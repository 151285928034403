.layout-profile {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1440px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
}

.layout-profil--sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 90;
    max-width: inherit;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);

    animation: slide-bottom .5s cubic-bezier(.25, .46, .45, .94) both;

    @keyframes slide-bottom {
        0% {
            transform: translateY(-100px)
        }

        100% {
            transform: translateY(0px)
        }
    }
}

.layout-profile__news {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #D1F1EF;
    color: #052523;
    padding: 13px 117px;



    @include respondTo(tablet) {
        padding: 7px 16px;
    }

    &--show {
        @extend .layout-profile__news;
        max-height: 60px;
    }

    &--hide {
        @extend .layout-profile__news;
        padding: 0;
        overflow: hidden;
        transition: all .5s linear;
        height: auto;
        max-height: 0;
        opacity: 0;
    }

    &__text {
        display: flex;

        >span {
            font-weight: 600;
            font-size: 13px;
            line-height: 150%;
            letter-spacing: 0.02em;
            margin-right: 12px;

            @include respondTo(tablet) {
                font-size: 12px;
                white-space: nowrap;
                max-width: 350px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            @include respondTo(phone) {
                white-space: nowrap;
                max-width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        >a {
            color: #0E5854;
            font-weight: 600;
            font-size: 13px;
            line-height: 150%;
            letter-spacing: 0.02em;

            @include respondTo(tablet) {
                font-size: 12px;
            }
        }
    }

    svg {
        cursor: pointer;
        animation: rotate-180-ccw .5s cubic-bezier(.25, .46, .45, .94) both;

        &:hover {
            animation: rotate-180-cw .5s cubic-bezier(.25, .46, .45, .94) both;
        }


    }
}

.layout-profile__navigations {
    padding: 13px 117px;
    background-color: #181818;
    color: white;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 99;

    @include respondTo(tablet) {
        flex-direction: column;
        background-color: #181818;
        padding: 0;
    }

    &__head {
        display: flex;
        align-items: center;

        @include respondTo(tablet) {
            width: 100%;
            padding: 16px;
        }

        &__logo img {
            height: 32px;
            margin-right: 32px;
        }

        &__menu-btn {
            display: none;
            margin-left: auto;

            @include respondTo(tablet) {
                display: block;
            }

            svg {
                path:last-child {
                    fill: #FFFFFF;

                }
            }
        }
    }

    &__item-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        @include respondTo(tablet) {
            display: none;
            flex-direction: column;
            align-items: flex-start;
            padding: 32px 0px;
            width: calc(100% - 32px);
            border-top: 1px solid #FFFFFF;
            // background-color: #16948C;
        }

    }

    &__item {
        font-weight: 600;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.02em;
        color: #FBFEFE;
        padding: 6px 18px;
        white-space: nowrap;
        margin-right: 24px;


        @include respondTo(tablet) {
            &:not(:last-child) {
                margin-bottom: 18px
            }
        }

    }

    &__item--active {
        @extend .layout-profile__navigations__item;
        background: #0E5854;
        border-radius: 20px;
    }

    &__setting {
        display: flex;
        align-items: center;
        margin-left: auto;

        @include respondTo(tablet) {
            flex-direction: column;
            order: -1;
        }

        &__auth {
            margin-right: 28px;

            @include respondTo(tablet) {
                margin-right: unset;
                align-self: flex-end;
                margin-bottom: 24px;
            }

            &__no-auth {
                >a {
                    padding: 8px 16px;
                    border-radius: 20px;

                    font-weight: 600;
                    font-size: 13px;
                    line-height: 150%;
                    color: #FBFEFE;
                    transition: .5s;

                    &:hover {
                        color: #323232;
                        background-color: #FBFEFE;
                    }

                    &:not(:last-child) {
                        margin-right: 12px;
                    }

                    @include respondTo(tablet) {
                        color: #FBFEFE;
                        background-color: transparent;
                        border: 1px solid #FFFFFF;
                    }
                }


            }

            &__authenticated {
                display: flex;
                position: relative;

                @include respondTo(tablet) {
                    flex-direction: column;
                    align-items: flex-end;
                }

                &__outer {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    >img {
                        background: #C4C4C4;
                        border: 1px solid #17948C;
                        width: 30px;
                        height: 30px;
                        object-fit: cover;
                        border-radius: 50%;
                        margin-right: 8px;
                    }

                    >span {
                        text-transform: capitalize;
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 150%;
                        letter-spacing: 0.02em;
                        color: #FBFEFE;
                        margin-right: 8px;
                    }

                    svg {
                        path {
                            fill: white;
                        }
                    }
                }

                &__inner {
                    background-color: #FFFFFF;
                    position: absolute;
                    z-index: 90;
                    top: 30px;
                    right: 0px;

                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
                    border-radius: 15px;

                    display: flex;
                    flex-direction: column;

                    @include respondTo(tablet) {
                        position: static;
                        top: unset;
                        right: unset;
                        background-color: transparent;
                        padding: 1px 2px;
                        box-shadow: unset;

                        flex-direction: row;
                        margin-top: 8px;
                    }

                    >hr {
                        align-self: center;
                        border: 1px solid #E0E0E0;
                        margin: unset;
                        width: calc(100% - 32px);

                        @include respondTo(tablet) {
                            display: none;
                        }
                    }

                    >a {
                        white-space: nowrap;
                        padding: 8px 18px;
                        text-align: left;

                        font-size: 13px;
                        line-height: 150%;
                        letter-spacing: 0.02em;
                        color: #4F4F4F;

                        &:first-child {
                            border-radius: 15px 15px 0 0;
                        }

                        &:last-child {
                            border-radius: 0 0 15px 15px;
                        }

                        &:hover {
                            background: #D0E9E8;
                        }

                        @include respondTo(tablet) {
                            border: 1px solid #E0E0E0;
                            border-radius: 20px !important;
                            padding: 8px 16px;

                            font-weight: 600;
                            color: #FBFEFE;

                            &:not(:last-child) {
                                margin-right: 8px;
                            }
                        }
                    }
                }
            }
        }

        &__lang {
            display: flex;
            align-items: center;
            position: relative;
            white-space: nowrap;

            @include respondTo(tablet) {
                flex-direction: column;
                margin-left: auto;
                margin-bottom: 24px;
            }

            &__info {
                align-self: flex-end;

                >*:not(:last-child) {
                    margin-right: 8px;
                }

                >span {
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 150%;
                    letter-spacing: 0.02em;
                    color: #FAFAFA;
                }

                svg {
                    path {
                        fill: white;
                    }
                }
            }

            &__option {
                background-color: #FFFFFF;
                position: absolute;
                padding: 1px 18px;
                z-index: 90;
                top: 30px;
                right: 0px;

                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
                border-radius: 15px;

                display: flex;
                flex-direction: column;


                @include respondTo(tablet) {
                    position: static;
                    top: unset;
                    right: unset;
                    background-color: transparent;
                    padding: 1px 2px;
                    box-shadow: unset;

                    flex-direction: row;
                    margin-top: 8px;
                }

                &__item {
                    white-space: nowrap;
                    padding: 8px 0;
                    text-align: left;

                    font-size: 13px;
                    line-height: 150%;
                    letter-spacing: 0.02em;
                    color: #4F4F4F;

                    &:not(:last-child) {
                        border-bottom: 1px solid #E0E0E0;
                        margin-right: 8px;
                    }

                    @include respondTo(tablet) {
                        border-bottom: unset;
                        border: 1px solid #E0E0E0;
                        border-radius: 20px;
                        padding: 8px 16px;

                        font-weight: 600;
                        color: #FBFEFE;


                        &:hover {
                            background: #FFFFFF;
                            color: #0A423F;
                        }
                    }
                }


                &__item--active {
                    @extend .layout-profile__navigations__setting__lang__option__item;

                    @include respondTo(tablet) {

                        background-color: #fff;
                        color: #181818;


                        &:hover {
                            background: #FFFFFF;
                            color: #0A423F;
                        }
                    }
                }
            }
        }
    }


}

.layout-profile__subnav {
    background-color: #FAFAFA;
    padding: 13px 117px;

    @include respondTo(tablet) {
        padding: 16px;
    }

    &__head {
        display: none;

        @include respondTo(tablet) {
            display: flex;
            justify-content: space-between;
        }

        >div {
            display: flex;
            align-items: center;
        }

        img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }

        span {
            font-weight: 600;
            font-size: 18px;
            line-height: 150%;
            letter-spacing: 0.02em;
            color: #052523;
        }

        svg {
            path {
                fill: #000000;
            }
        }
    }

    &__item {
        display: flex;
        width: 100%;

        font-weight: normal;
        font-size: 13px;
        line-height: 150%;
        letter-spacing: 0.02em;
        color: #798685;

        @include respondTo(tablet) {
            display: none;
            flex-direction: column;

            >:nth-child(1) {
                display: none;
            }

            >* {
                padding: 6px 0;
            }
        }

        >:nth-child(1) {
            font-weight: 600;
            color: #031615;

            >span:nth-child(1) {
                margin-right: 8px;
            }
        }

        >*:not(:last-child) {
            margin-right: 24px;
        }
    }
}

.layout-profile__footer {
    background-color: #052523;
    color: #D9DBE1;
    padding: 69px 165px 23px;
    display: flex;
    flex-direction: column;
    z-index: 10;

    &__iconDownload {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 100px;

        @include respondTo(tablet) {
            width: 20rem;
          }

        a {
            margin: 20px 12px 0 0;
        }
        
        img {
            width: 135px;
            height: 40px;
            object-fit: cover;

            @include respondTo(tablet) {
                width: 108.61px;
                height: 32px;
              }
        }
    }

    @include respondTo(tablet) {
        padding: 32px 16px;
    }

    &__sitemap {
        margin-bottom: 40px;
        display: flex;


        @include respondTo(tablet) {
            flex-direction: column;
        }


        &__grid {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 40px;

            @include respondTo(tablet) {
                grid-template-columns: repeat(2, 50%);
                grid-gap: 32px 22px;
                margin-bottom: 22px;

                div {
                    &:nth-child(1) {
                        order: 1;
                    }

                    &:nth-child(2) {
                        order: 3;
                    }

                    &:nth-child(3) {
                        order: 2;
                    }

                    &:nth-child(4) {
                        order: 4
                    }
                }
            }

            &__link {
                display: flex;
                flex-direction: column;

                >* {
                    word-break: break-all;
                }

                >span {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    color: #FFFFFF;
                    margin-bottom: 24px;
                }

                >a {
                    margin-bottom: 12px;
                    font-size: 14px;
                    line-height: 24px;
                    color: #D9DBE1;
                    margin-bottom: 10px;
                }
            }
        }



        &__info {
            display: flex;
            flex-direction: column;
            max-width: 255px;
            margin-right: 30px;

            >img {
                margin-bottom: 24px;
                align-self: flex-start;
            }

            >span {
                font-size: 14px;
                line-height: 24px;
                font-feature-settings: 'liga'off;
                color: #D9DBE1;
            }

            @include respondTo(tablet) {
                order: 1;
                margin-right: unset;
            }
        }


    }

    &__copy-right {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: #D9DBE1;

        @include respondTo(tablet) {
            text-align: center;
            font-size: 12px;
            font-weight: normal;
        }
    }

}


.layout-profile__main {
    min-height: 50vh;
}

.layout-profile__cta {
    display: none;

    @include respondTo(tablet) {
        display: flex;
        align-items: center;
        position: fixed;
        width: 100%;
        padding: 19px 8px;
        z-index: 90;
        bottom: 0;

        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
        border-radius: 15px 15px 0px 0px;

        >svg {
            min-width: 18px;
            min-height: 18px;
            margin-right: 8px;
        }

        &__desc {
            display: flex;
            flex-direction: column;
            margin-left: 12px;

            >span:nth-child(1) {
                font-weight: bold;
                font-size: 14px;
                line-height: 113%;
                margin-bottom: 8px;
                color: #052523;
            }

            >span:nth-child(2) {
                font-weight: 500;
                font-size: 10px;
                line-height: 147.5%;
                letter-spacing: 0.02em;
                color: #828282;
            }
        }

        >a {

            background: #16948C;
            border-radius: 15px;
            padding: 8px 12px;
            background: #16948C;
            margin-left: auto;

            font-weight: 600;
            font-size: 12px;
            line-height: 113%;
            color: #FFFFFF;
        }
    }

}

.dot {
    display: inline-flex;
    width: 10px;
    height: 10px;
    border-radius: 50%;


    &--yellow {
        @extend .dot;
        background-color: #EFD50C;
    }

    &--tosca {
        @extend .dot;
        background-color: #4AC7BF;
        ;
    }
}