.membership{
    padding-top: 62px;
    padding-bottom: 58px;
    background: #fff;
    z-index: 0;
    
    @media #{$phoneMQ} {
        padding-top: 47px;
        padding-bottom: 45px;
    }

    .--loader{
        padding-top: 50px;

        @media #{$phoneMQ} {
            margin-bottom: 20px;
        }
    }

    &__content{
        padding-bottom: 16px;

        &__info{
            margin-left: 115px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            
            @media #{$tabletMQ} {
                margin-left: 0;
                padding: 0 21px;
            }

            >h2{
                margin-top: 0;
                font-style: normal;
                font-weight: bold;
                font-size: 30px;
                line-height: 27px;
                display: flex;
                align-items: center;
                letter-spacing: 0.03em;
                color: #000000;
                
                @media #{$tabletMQ} {
                    font-size: 24px;
                }
            }

            >h3{
                margin-top: 18px;

                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                display: flex;
                align-items: center;
                letter-spacing: 0.03em;
                color: #000000;

                @media #{$tabletMQ} {
                    font-size: 12px;
                }
            }

            >span{
                margin-top: 11px;

                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                display: flex;
                align-items: center;
                letter-spacing: 0.03em;
                color: #000000;
                opacity: 0.3;

                @media #{$phoneMQ} {
                    display: none;
                }
            }

            >p{
                margin-top: 24px;

                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                display: flex;
                align-items: center;
                letter-spacing: 0.03em;
                color: #17948C;

                @media #{$tabletMQ} {
                    font-size: 14px;
                }
            }
        }

        &__list{
            margin-top: 35px;

            @media #{$phoneMQ} {
                margin-top: 13px;
            }

            &__item{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;

                padding-top: 50px;
                
                @media #{$phoneMQ} {
                    // width: 342px;
                    padding-top: 26px;

                    width: 311px;
                }
                

                >div{   
                    padding: 16px;
                    border-radius: 20px;
                    width: 100%;
                    background:#17948C;
                    margin-bottom: 16px;
                    
                    @media #{$phoneMQ} {
                        margin-bottom: 64px;
                    }

                    >.benefit{
                        background: #FBFEFE;
                        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
                        border-radius: 15px;
                        margin-bottom: 26px;
                        padding: 13px 21px;

                        >div:nth-child(1){
                            display: flex;
                            align-items: center;
                            
                            >h2{
                                font-style: normal;
                                font-weight: 600;
                                font-size: 18px;
                                line-height: 19px;
                                letter-spacing: 0.03em;
                                color: #031615;

                                margin-top: 10px;

                                @media #{$tabletMQ} {
                                    font-size: 14px;
                                }

                                margin-right: 10px;
                            }

                            >div{
                                display: flex;
                                align-items: center;

                                >img:nth-child(1){

                                }

                                >img:nth-child(2){
                                    margin-left: -8px;
                                }
                            }

                            >svg{
                                margin-left: 4px;
                            }
                        }   

                        >div:nth-child(2){
                            margin-top: 14px;

                            >section{
                                margin-bottom: 12px;
                                display: flex;
                                align-items: center;

                                
                                >div{
                                    width: 42px;
                                    height: 42px;
                                    border-radius: 10px;
                                    padding: 6px 12px;

                                    color: #fff;

                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin-right: 12px;
                                    font-size: 14px;
                                }

                                >h2{
                                    margin-top: 0;
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 14px;
                                    line-height: 17px;
                                    letter-spacing: 0.01em;
                                    color: #495050;

                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2; /* number of lines to show */
                                    -webkit-box-orient: vertical;

                                    >p{
                                        >span:nth-child(1){
                                            color: #2F80ED;
                                        }
                                        
                                        >span:nth-child(2){
                                            color: #27AE60;
                                        }
                                    }
                                    
                                    @media #{$tabletMQ} {
                                        font-size: 12px;
                                    }
                                }
                            }

                            >section:nth-child(1){
                                >div{
                                    background: #1F7899;
                                }   
                            }

                            >section:nth-child(2){
                                >div{
                                    background: #66D57B;
                                }   
                            }
                        }

                    }

                    >.option{
                        margin-top: 28px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: 16px;

                        >section{
                            cursor: pointer;
                            background: #FFFFFF;
                            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 20px rgba(0, 0, 0, 0.1);
                            border-radius: 10px;
                            padding: 14px 17px;
                            
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-between;
                            
                            margin-bottom: 13px;
                            width: 100%;
                            
                            @media #{$tabletMQ} {
                                padding: 12px 14px;
                            }
                            
                            >h4{
                                margin-top: -22px;
                                
                                background: #114B5F;
                                border-radius: 5px;
                                display: flex;
                                align-items: center;
                                padding: 4px 8px;
    
                                font-style: normal;
                                font-weight: bold;
                                font-size: 10px;
                                line-height: 10px;
    
                                color: #FFFFFF;
                            }
    
                            >div{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                width: 100%;

                               >div{
                                    display: flex;
                                    align-items: center;

                                    >div:nth-child(1){
                                        border: 1px solid #17948C;
                                        box-sizing: border-box;
                                        min-height: 24px;
                                        min-width: 24px;
                                        border-radius: 50%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        margin-right: 14px;
                                        cursor: pointer;
                                        
                                        @media #{$tabletMQ} {
                                            margin-right: 8px;
                                        }

                                        >span{
                                            width: 16px;
                                            height: 16px;
                                            background: #17948C;
                                            border-radius: 50%;
                                        }
                                    }

                                    >div:nth-child(2){
                                        display: flex;
                                        flex-direction: column;

                                        >span{
                                                                            
                                            @media #{$phoneMQ} {
                                                font-size: 10px;
                                            }
                                        }

                                        >span:nth-child(1){
                                            font-style: normal;
                                            font-weight: 600;
                                            font-size: 14px;
                                            line-height: 10px;
                                            color: #031615;
                                            
                                                                                                                        
                                            @media #{$phoneMQ} {
                                                font-style: normal;
                                                font-weight: 600;
                                                font-size: 10px;
                                                line-height: 10px;
                                                color: #031615;
                                            }
                                        }

                                        
                                        >span:nth-child(2){
                                            margin-top: 7px;
                                            font-style: normal;
                                            font-weight: 600;
                                            font-size: 12px;
                                            line-height: 14px;
                                            letter-spacing: 0.01em;
                                            color: #17948C;

                                            @media #{$phoneMQ} {
                                                font-style: normal;
                                                font-weight: 600;
                                                font-size: 10px;
                                                line-height: 14px;
                                                letter-spacing: 0.01em;
                                                color: #17948C;
                                            }
                                        }
                                    }
                               }

                                >span{
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 18px;
                                    line-height: 19px;
                                    text-align: center;
                                    letter-spacing: 0.03em;
                                    color: #17948C;

                                                                                                                
                                    @media #{$phoneMQ} {
                                        font-size: 14px;
                                    }

                                    >span{
                                        font-weight: normal;
                                        font-size: 11px;
                                        line-height: 24px;
                                        color: #17948C;
                                                                                                    
                                        @media #{$phoneMQ} {
                                            font-size: 11px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    >.assurance{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        >div{
                            display: flex;
                            align-items: center;

                            >section:nth-child(1){
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background: #fff;
                                padding: 4px 8px;
                                margin-right: 9px;
                                border-radius: 10px;
                            }

                            >section:nth-child(2){
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: flex-start;

                                >h3{
                                    margin-top: 0;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 10px;
                                    letter-spacing: 0.01em;
                                    color: #F7F8F8;
                                }

                                >h2{
                                    margin-top: 0;
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 12px;
                                    letter-spacing: 0.01em;
                                    color: #F7F8F8;
                                }
                            }

                        }

                        >a{
                            >svg{
                                cursor: pointer;
                            }
                        }
                    }

                }   

                >button{
                    width: 100%;
                }
            }

        }

        &__more{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -34px;
            position: absolute;
            right: 0;
            left: 0;
            
            @media #{$phoneMQ} {
                margin-top: -44px;
            }

            >p{
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 17px;
                text-align: center;
                letter-spacing: 0.01em;
                color: #031615;
    
                >a{
                    cursor: pointer;
                    >span{
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 17px;
                        letter-spacing: 0.01em;
                        color: #17948C;
                        cursor: pointer;
                    }
                }
            }
        }

        &__button{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            @media #{$phoneMQ} {
                padding: 0 18px;
            }

            >a{
                @media #{$phoneMQ} {
                    width: 100%;
                }
            }
        }

        &__modal{
            width: 353px;
            height: 603px;
            background: #FFFFFF;
            border-radius: 15px;
            padding: 18px 18px;

            &__header{
               display: flex;
               justify-content: space-between;

               >img{
                   cursor: pointer;
                   width: 24px;
                   height: 24px;
               }
            }

            >img {
                margin-left: 3.2rem;
                margin-bottom: 1rem;

            }

            &__title{
                font-family: Poppins;
                font-weight: bold;
                font-size: 24px;
                line-height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                letter-spacing: 0.03em;
                margin-bottom: 14px;
                color: #000000;
            }

            &__section{
                margin-bottom: 12px;

                &__title{
                    display: flex;
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 14px;
                    letter-spacing: 0.01em;
                    color: #000000;
                    margin-bottom: 8px;
                }
    
                &__dots{ 
                    width: 8px;
                    height: 8px;
                    background: #17948C;
                    border-radius: 50px;
                    align-self: center;
                    margin-right: 12px;
                }
    
                &__desc{
                    font-family: Poppins;
                    font-weight: normal;
                    font-size: 10px;
                    letter-spacing: 0.03em;
                    color: #000000;
                    mix-blend-mode: normal;
                    opacity: 0.8;
                    margin-left: 20px;
                }
            }

            &__tnc-btn{
                background: rgba(29, 186, 175, 0.15);;
                border-radius: 10px;
                width: 100%;
                padding: 9px 12px;
                margin-bottom: 27px;
                justify-content: space-between;
          
                display: flex;
                align-items: center;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                letter-spacing: 0.03em;
                color: #17948C;
            }

            &__bottom-container{
                display: flex;
                margin-bottom: 26px;


                >span{
                    display: flex;
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 16px;
                    align-items: center;
                    letter-spacing: 0.03em;
                    color: #000000;
                    opacity: 0.5;
                }

                &__agree{
                    width: 24px;
                    height: 24px;
                    margin-right: 12px;
                    cursor: pointer;
                }
            }
        }
        
    }

    // For SLider 
    .slick-dots{
        // bottom: 0 !important;
        display: flex !important;
        justify-content: center;
        top: 0 !important;
        position: absolute;
        z-index: 11;

        // background-color: #1F7899;
        height: 30px;
        
        @media #{$phoneMQ} {
            top: 0;
            bottom: auto;
            height: 20px;
        }
        
        >li{
            background: #C4C4C4 !important;
            width: 16px;
            height: 16px;
            
            @media #{$phoneMQ} {
                width: 8px !important;
                height: 8px !important;
            }
            
        }
        
        .slick-active{
            background: #4AC7BF !important;
    
            @media #{$phoneMQ} {
                width: 8px !important;
                height: 8px !important;
            }
        }
    }
    
    .slick-slider{
        padding-bottom: 38px !important;
        
        @media #{$phoneMQ} {
            padding-bottom: 14px !important;
        }
    }
    
    .slick-slide {
        padding: 0 20px;
        z-index: 10;
                
        @media #{$tabletMQ} {
            padding: 0 8px;
        }

        @media #{$phoneMQ} {
            padding-left: 16px;
            padding-right: 0px;
        }
    }
        
    .slick-next:before, .slick-prev:before {
        display: none;
    }

}